var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mx-1" },
    [
      _c(
        "b-overlay",
        {
          attrs: { center: "", show: _vm.isLoading, rounded: "sm" },
          scopedSlots: _vm._u([
            {
              key: "overlay",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "text-center mt-5" },
                    [
                      _c("base-icon", {
                        attrs: { name: "loading", width: "35", height: "35" },
                      }),
                      _vm._v(" "),
                      _c("p", { attrs: { id: "cancel-label" } }, [
                        _vm._v("Operazione in corso..."),
                      ]),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          !_vm.isLoading
            ? _c("div", [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.showForm,
                        expression: "!showForm",
                      },
                    ],
                  },
                  [
                    _c("validation-observer", {
                      ref: "observer1",
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function ({ handleSubmit }) {
                              return [
                                _c(
                                  "b-form",
                                  {
                                    on: {
                                      submit: function ($event) {
                                        $event.preventDefault()
                                        return handleSubmit(_vm.proceed)
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "b-card",
                                      {
                                        attrs: {
                                          header: "true",
                                          "header-tag": "header",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex justify-content-between align-items-center",
                                            attrs: { slot: "header" },
                                            slot: "header",
                                          },
                                          [
                                            _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "mb-0 d-inline-block align-middle",
                                              },
                                              [
                                                _c("b", [
                                                  _vm._v(
                                                    _vm._s(
                                                      "Seleziona".toUpperCase()
                                                    )
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          [
                                            _c("base-radio", {
                                              attrs: {
                                                name: _vm.beForm[_vm.rep]
                                                  .status_registry.label,
                                                vid: "status_registry",
                                                label: _vm.getDictionary(
                                                  "status_registry",
                                                  _vm.rep
                                                ),
                                                options:
                                                  _vm.beForm[_vm.rep]
                                                    .status_registry.options,
                                                rules:
                                                  _vm.getRules(
                                                    "status_registry"
                                                  ),
                                              },
                                              model: {
                                                value:
                                                  _vm.form[_vm.rep]
                                                    .status_registry,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form[_vm.rep],
                                                    "status_registry",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "form[rep].status_registry",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _vm.form[_vm.rep]
                                              .status_registry !== null
                                              ? _c("b-row", [
                                                  _c(
                                                    "div",
                                                    { staticClass: "col-sm-6" },
                                                    [
                                                      _vm.form[_vm.rep]
                                                        .status_registry === 0
                                                        ? _c("base-input", {
                                                            attrs: {
                                                              vid: "attribute_NINO_value",
                                                              name: _vm.beForm[
                                                                _vm.rep
                                                              ]
                                                                .attribute_NINO_value
                                                                .label,
                                                              label:
                                                                _vm.getDictionary(
                                                                  "attribute_NINO_value",
                                                                  _vm.rep
                                                                ),
                                                              placeholder:
                                                                "Inserisci un codice fiscale",
                                                              rules:
                                                                _vm.getRules(
                                                                  "attribute_NINO_value"
                                                                ),
                                                              textcase: "upper",
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.form[
                                                                  _vm.rep
                                                                ]
                                                                  .attribute_NINO_value,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.form[
                                                                      _vm.rep
                                                                    ],
                                                                    "attribute_NINO_value",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "form[rep].attribute_NINO_value",
                                                            },
                                                          })
                                                        : _c("base-input", {
                                                            attrs: {
                                                              vid: "attribute_CREG_value",
                                                              name: _vm.beForm[
                                                                _vm.rep
                                                              ]
                                                                .attribute_CREG_value
                                                                .label,
                                                              label:
                                                                _vm.getDictionary(
                                                                  "attribute_CREG_value",
                                                                  _vm.rep
                                                                ),
                                                              placeholder:
                                                                "Inserisci una partita IVA",
                                                              rules:
                                                                _vm.getRules(
                                                                  "attribute_CREG_value"
                                                                ),
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.form[
                                                                  _vm.rep
                                                                ]
                                                                  .attribute_CREG_value,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.form[
                                                                      _vm.rep
                                                                    ],
                                                                    "attribute_CREG_value",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "form[rep].attribute_CREG_value",
                                                            },
                                                          }),
                                                      _vm._v(" "),
                                                      _c(
                                                        "b-button",
                                                        {
                                                          attrs: {
                                                            variant: "lisaweb",
                                                            type: "submit",
                                                            disabled:
                                                              _vm.isValidating,
                                                          },
                                                        },
                                                        [
                                                          _c("b-spinner", {
                                                            directives: [
                                                              {
                                                                name: "show",
                                                                rawName:
                                                                  "v-show",
                                                                value:
                                                                  _vm.isValidating,
                                                                expression:
                                                                  "isValidating",
                                                              },
                                                            ],
                                                            attrs: {
                                                              small: "",
                                                              type: "grow",
                                                            },
                                                          }),
                                                          _vm._v("Procedi"),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ])
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        4057506278
                      ),
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showForm,
                        expression: "showForm",
                      },
                    ],
                  },
                  [
                    _c("validation-observer", {
                      ref: "observer2",
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function ({ handleSubmit, invalid }) {
                              return [
                                _c(
                                  "div",
                                  { staticStyle: { display: "none" } },
                                  [
                                    _c("base-input", {
                                      attrs: { vid: "status_registry" },
                                      model: {
                                        value:
                                          _vm.form[_vm.rep].status_registry,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form[_vm.rep],
                                            "status_registry",
                                            $$v
                                          )
                                        },
                                        expression: "form[rep].status_registry",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "mt-2" },
                                  [
                                    _c(
                                      "b-card",
                                      {
                                        attrs: {
                                          header: "Dati identificativi",
                                          "header-tag": "header",
                                        },
                                      },
                                      [
                                        _vm.form[_vm.rep].status_registry === 0
                                          ? _c(
                                              "b-card-text",
                                              [
                                                _c("b-row", [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-input", {
                                                        attrs: {
                                                          vid: "attribute_SURN_value",
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ].attribute_SURN_value
                                                            .label,
                                                          label:
                                                            _vm.getDictionary(
                                                              "attribute_SURN_value",
                                                              _vm.rep
                                                            ),
                                                          placeholder:
                                                            "Inserisci un cognome",
                                                          rules: _vm.getRules(
                                                            "attribute_SURN_value"
                                                          ),
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .attribute_SURN_value,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "attribute_SURN_value",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].attribute_SURN_value",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-input", {
                                                        attrs: {
                                                          vid: "attribute_NAME_value",
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ].attribute_NAME_value
                                                            .label,
                                                          label:
                                                            _vm.getDictionary(
                                                              "attribute_NAME_value",
                                                              _vm.rep
                                                            ),
                                                          placeholder:
                                                            "Inserisci un nome",
                                                          rules: _vm.getRules(
                                                            "attribute_NAME_value"
                                                          ),
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .attribute_NAME_value,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "attribute_NAME_value",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].attribute_NAME_value",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-2",
                                                    },
                                                    [
                                                      _c("base-select", {
                                                        attrs: {
                                                          vid: "attribute_TITL_value",
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ].attribute_TITL_value
                                                            .label,
                                                          label:
                                                            _vm.getDictionary(
                                                              "attribute_TITL_value",
                                                              _vm.rep
                                                            ),
                                                          options:
                                                            _vm.beForm[_vm.rep]
                                                              .attribute_TITL_value
                                                              .options,
                                                          placeholder:
                                                            "Inserisci un titolo",
                                                          rules: _vm.getRules(
                                                            "attribute_TITL_value"
                                                          ),
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .attribute_TITL_value,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "attribute_TITL_value",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].attribute_TITL_value",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-2",
                                                    },
                                                    [
                                                      _c("base-radio", {
                                                        attrs: {
                                                          vid: "attribute_SEX_value",
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ].attribute_SEX_value
                                                            .label,
                                                          label:
                                                            _vm.getDictionary(
                                                              "attribute_SEX_value",
                                                              _vm.rep
                                                            ),
                                                          options:
                                                            _vm.beForm[_vm.rep]
                                                              .attribute_SEX_value
                                                              .options,
                                                          rules: _vm.getRules(
                                                            "attribute_SEX_value"
                                                          ),
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .attribute_SEX_value,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "attribute_SEX_value",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].attribute_SEX_value",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-2",
                                                    },
                                                    [
                                                      _c("base-input", {
                                                        attrs: {
                                                          vid: "attribute_NINO_value",
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ].attribute_NINO_value
                                                            .label,
                                                          label:
                                                            _vm.getDictionary(
                                                              "attribute_NINO_value",
                                                              _vm.rep
                                                            ),
                                                          rules: _vm.getRules(
                                                            "attribute_NINO_value"
                                                          ),
                                                          disabled: "",
                                                          textcase: "upper",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .attribute_NINO_value,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "attribute_NINO_value",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].attribute_NINO_value",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]),
                                                _vm._v(
                                                  "\n                Nato a:\n                "
                                                ),
                                                _c("b-row", [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-select", {
                                                        attrs: {
                                                          vid: "attribute_NOB_value",
                                                          name: _vm.form[
                                                            _vm.rep
                                                          ].attribute_NOB_value
                                                            .label,
                                                          label:
                                                            _vm.getDictionary(
                                                              "attribute_NOB_value",
                                                              _vm.rep
                                                            ),
                                                          options:
                                                            _vm.form
                                                              .optBornCountries,
                                                          taggable: false,
                                                          multiple: false,
                                                          closeOnSelect: true,
                                                        },
                                                        on: {
                                                          select:
                                                            _vm.onSelectBornCountry,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .attribute_NOB_value,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "attribute_NOB_value",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].attribute_NOB_value",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  !_vm.isBornForeign &&
                                                  _vm.form.born_province
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "form-group col-md-3",
                                                        },
                                                        [
                                                          _c("base-select", {
                                                            attrs: {
                                                              name: "born_province",
                                                              vid: "born_province",
                                                              label:
                                                                "Provincia",
                                                              options:
                                                                _vm.form
                                                                  .optBornProvinces,
                                                              taggable: false,
                                                              multiple: false,
                                                              closeOnSelect: true,
                                                            },
                                                            on: {
                                                              select:
                                                                _vm.onSelectBornProvince,
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.form
                                                                  .born_province,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.form,
                                                                    "born_province",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "form.born_province",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  !_vm.isBornForeign
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "form-group col-md-3",
                                                        },
                                                        [
                                                          _c("base-select", {
                                                            attrs: {
                                                              vid: "attribute_POB_value",
                                                              name: _vm.beForm[
                                                                _vm.rep
                                                              ]
                                                                .attribute_POB_value
                                                                .label,
                                                              label:
                                                                _vm.getDictionary(
                                                                  "attribute_POB_value",
                                                                  _vm.rep
                                                                ),
                                                              rules:
                                                                _vm.getRules(
                                                                  "attribute_POB_value"
                                                                ),
                                                              options:
                                                                _vm.form
                                                                  .optBornCouncils,
                                                              taggable: false,
                                                              multiple: false,
                                                              closeOnSelect: true,
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.form[
                                                                  _vm.rep
                                                                ]
                                                                  .attribute_POB_value,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.form[
                                                                      _vm.rep
                                                                    ],
                                                                    "attribute_POB_value",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "form[rep].attribute_POB_value",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      )
                                                    : _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "form-group col-md-3",
                                                        },
                                                        [
                                                          _c("base-input", {
                                                            attrs: {
                                                              vid: "attribute_POB2_value",
                                                              name: _vm.beForm[
                                                                _vm.rep
                                                              ]
                                                                .attribute_POB2_value
                                                                .label,
                                                              label:
                                                                _vm.getDictionary(
                                                                  "attribute_POB2_value",
                                                                  _vm.rep
                                                                ),
                                                              rules:
                                                                _vm.getRules(
                                                                  "attribute_POB2_value"
                                                                ),
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.form[
                                                                  _vm.rep
                                                                ]
                                                                  .attribute_POB2_value,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.form[
                                                                      _vm.rep
                                                                    ],
                                                                    "attribute_POB2_value",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "form[rep].attribute_POB2_value",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-datepicker", {
                                                        attrs: {
                                                          vid: "attribute_DOB_value",
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ].attribute_DOB_value
                                                            .label,
                                                          label:
                                                            _vm.getDictionary(
                                                              "attribute_DOB_value",
                                                              _vm.rep
                                                            ),
                                                          rules: _vm.getRules(
                                                            "attribute_DOB_value"
                                                          ),
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .attribute_DOB_value,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "attribute_DOB_value",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].attribute_DOB_value",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]),
                                              ],
                                              1
                                            )
                                          : _c(
                                              "b-card-text",
                                              [
                                                _c("b-row", [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-input", {
                                                        attrs: {
                                                          vid: "attribute_CNAM_value",
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ].attribute_CNAM_value
                                                            .label,
                                                          label:
                                                            _vm.getDictionary(
                                                              "attribute_CNAM_value",
                                                              _vm.rep
                                                            ),
                                                          placeholder:
                                                            "Inserisci una ragione sociale",
                                                          rules: _vm.getRules(
                                                            "attribute_CNAM_value"
                                                          ),
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .attribute_CNAM_value,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "attribute_CNAM_value",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].attribute_CNAM_value",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-input", {
                                                        attrs: {
                                                          vid: "attribute_CREG_value",
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ].attribute_CREG_value
                                                            .label,
                                                          label:
                                                            _vm.getDictionary(
                                                              "attribute_CREG_value",
                                                              _vm.rep
                                                            ),
                                                          rules: _vm.getRules(
                                                            "attribute_CREG_value"
                                                          ),
                                                          disabled: "",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .attribute_CREG_value,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "attribute_CREG_value",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].attribute_CREG_value",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-input", {
                                                        attrs: {
                                                          vid: "attribute_NINO_value",
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ].attribute_NINO_value
                                                            .label,
                                                          label:
                                                            _vm.getDictionary(
                                                              "attribute_NINO_value",
                                                              _vm.rep
                                                            ),
                                                          rules: _vm.getRules(
                                                            "attribute_NINO_value"
                                                          ),
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .attribute_NINO_value,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "attribute_NINO_value",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].attribute_NINO_value",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-select", {
                                                        attrs: {
                                                          vid: "attribute_LIAB_value",
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ].attribute_LIAB_value
                                                            .label,
                                                          label:
                                                            _vm.getDictionary(
                                                              "attribute_LIAB_value",
                                                              _vm.rep
                                                            ),
                                                          rules: _vm.getRules(
                                                            "attribute_LIAB_value"
                                                          ),
                                                          options:
                                                            _vm.beForm[_vm.rep]
                                                              .attribute_LIAB_value
                                                              .options,
                                                          taggable: false,
                                                          multiple: false,
                                                          closeOnSelect: true,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .attribute_LIAB_value,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "attribute_LIAB_value",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].attribute_LIAB_value",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _vm.origin?.routeName != "module.PEASY.PRIV"
                                  ? _c(
                                      "div",
                                      { staticClass: "mt-1" },
                                      [
                                        _c("address-book-form", {
                                          attrs: {
                                            beECForm: _vm.beForm[_vm.addrBook],
                                            repository: _vm.addrBook,
                                          },
                                          model: {
                                            value: _vm.address_book_form,
                                            callback: function ($$v) {
                                              _vm.address_book_form = $$v
                                            },
                                            expression: "address_book_form",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.origin?.routeName != "module.PEASY.PRIV"
                                  ? _c(
                                      "div",
                                      { staticClass: "mt-1" },
                                      [
                                        _c("correspondence-form", {
                                          attrs: {
                                            beECForm: _vm.beForm[_vm.cor],
                                            repository: _vm.cor,
                                            dynamicRules: false,
                                            rules: {
                                              attribute_NOR_value: _vm.getRules(
                                                "attribute_NOR_value",
                                                "correspondence"
                                              ),
                                              attribute_CITY_value:
                                                _vm.getRules(
                                                  "attribute_CITY_value",
                                                  "correspondence"
                                                ),
                                              attribute_CITY2_value:
                                                _vm.getRules(
                                                  "attribute_CITY2_value",
                                                  "correspondence"
                                                ),
                                              home_province: { required: true },
                                              attribute_ZIPC_value: {},
                                              attribute_ADDR_value:
                                                _vm.getRules(
                                                  "attribute_ADDR_value",
                                                  "correspondence"
                                                ),
                                              attribute_NAME2_value: {},
                                            },
                                          },
                                          model: {
                                            value: _vm.correspondence_form,
                                            callback: function ($$v) {
                                              _vm.correspondence_form = $$v
                                            },
                                            expression: "correspondence_form",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.origin?.routeName === "module.PEASY.PRIV"
                                  ? _c(
                                      "div",
                                      { staticClass: "mt-1" },
                                      [
                                        _c("data-protection-form", {
                                          attrs: {
                                            beECForm: _vm.beForm[_vm.dtPrt],
                                            repository: _vm.dtPrt,
                                            rules: {
                                              intermediary_consent:
                                                _vm.getRules(
                                                  "intermediary_consent",
                                                  "data_protection"
                                                ),
                                              traditional_consent: _vm.getRules(
                                                "traditional_consent",
                                                "data_protection"
                                              ),
                                              electronic_consent: _vm.getRules(
                                                "electronic_consent",
                                                "data_protection"
                                              ),
                                              thirdparty_consent: _vm.getRules(
                                                "thirdparty_consent",
                                                "data_protection"
                                              ),
                                            },
                                          },
                                          model: {
                                            value: _vm.data_protection_form,
                                            callback: function ($$v) {
                                              _vm.data_protection_form = $$v
                                            },
                                            expression: "data_protection_form",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value:
                                          _vm.origin?.routeName !=
                                          "module.PEASY.PRIV",
                                        expression:
                                          "origin?.routeName != 'module.PEASY.PRIV'",
                                      },
                                    ],
                                    staticClass: "mt-2",
                                  },
                                  [
                                    _vm.form[_vm.rep].status_registry === 1
                                      ? _c(
                                          "b-card",
                                          {
                                            attrs: {
                                              header: "Altri Dati",
                                              "header-tag": "header",
                                            },
                                          },
                                          [
                                            _c(
                                              "b-card-text",
                                              [
                                                _c("b-row", [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-input", {
                                                        attrs: {
                                                          vid: "attribute_PROF_value",
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ].attribute_PROF_value
                                                            .label,
                                                          label: "Attività",
                                                          placeholder:
                                                            "Inserisci il numero di documento",
                                                          rules: _vm.getRules(
                                                            "attribute_PROF_value"
                                                          ),
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .attribute_PROF_value,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "attribute_PROF_value",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].attribute_PROF_value",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-select", {
                                                        attrs: {
                                                          vid: "attribute_CHAN_value",
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ].attribute_CHAN_value
                                                            .label,
                                                          label:
                                                            "Canale Preferito",
                                                          options:
                                                            _vm.beForm[_vm.rep]
                                                              .attribute_CHAN_value
                                                              .options,
                                                          rules: _vm.getRules(
                                                            "attribute_CHAN_value"
                                                          ),
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .attribute_CHAN_value,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "attribute_CHAN_value",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].attribute_CHAN_value",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value:
                                          _vm.origin?.routeName !=
                                          "module.PEASY.PRIV",
                                        expression:
                                          "origin?.routeName != 'module.PEASY.PRIV'",
                                      },
                                    ],
                                    staticClass: "mt-2",
                                  },
                                  [
                                    _vm.form[_vm.rep].status_registry === 0
                                      ? _c(
                                          "b-card",
                                          {
                                            attrs: {
                                              header: "Documento",
                                              "header-tag": "header",
                                            },
                                          },
                                          [
                                            _c(
                                              "b-card-text",
                                              [
                                                _c("b-row", [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-radio", {
                                                        attrs: {
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ].attribute_DOCT_value
                                                            .label,
                                                          vid: "attribute_DOCT_value",
                                                          label:
                                                            _vm.getDictionary(
                                                              "attribute_DOCT_value",
                                                              _vm.rep
                                                            ),
                                                          options:
                                                            _vm.beForm[_vm.rep]
                                                              .attribute_DOCT_value
                                                              .options,
                                                          rules: _vm.getRules(
                                                            "attribute_DOCT_value"
                                                          ),
                                                          stacked: "",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .attribute_DOCT_value,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "attribute_DOCT_value",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].attribute_DOCT_value",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]),
                                                _vm._v(" "),
                                                _c("b-row", [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-input", {
                                                        attrs: {
                                                          vid: "attribute_DOCN_value",
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ].attribute_DOCN_value
                                                            .label,
                                                          label:
                                                            _vm.getDictionary(
                                                              "attribute_DOCN_value",
                                                              _vm.rep
                                                            ),
                                                          placeholder:
                                                            "Inserisci il numero di documento",
                                                          rules: _vm.getRules(
                                                            "attribute_DOCN_value"
                                                          ),
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .attribute_DOCN_value,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "attribute_DOCN_value",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].attribute_DOCN_value",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-select", {
                                                        attrs: {
                                                          vid: "attribute_ISPL_value",
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ].attribute_ISPL_value
                                                            .label,
                                                          label:
                                                            _vm.getDictionary(
                                                              "attribute_ISPL_value",
                                                              _vm.rep
                                                            ),
                                                          placeholder:
                                                            "Digitare le lettere iniziali...",
                                                          options:
                                                            _vm.ajaxOptions,
                                                          isLoading:
                                                            _vm.isLoadingAjax,
                                                          rules: _vm.getRules(
                                                            "attribute_ISPL_value"
                                                          ),
                                                        },
                                                        on: {
                                                          "search-change":
                                                            _vm.getAjaxOptions,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .attribute_ISPL_value,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "attribute_ISPL_value",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].attribute_ISPL_value",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-datepicker", {
                                                        attrs: {
                                                          vid: "attribute_DOCS_value",
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ].attribute_DOCS_value
                                                            .label,
                                                          label:
                                                            _vm.getDictionary(
                                                              "attribute_DOCS_value",
                                                              _vm.rep
                                                            ),
                                                          rules: _vm.getRules(
                                                            "attribute_DOCS_value"
                                                          ),
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .attribute_DOCS_value,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "attribute_DOCS_value",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].attribute_DOCS_value",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-datepicker", {
                                                        attrs: {
                                                          vid: "attribute_DOCX_value",
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ].attribute_DOCX_value
                                                            .label,
                                                          label:
                                                            _vm.getDictionary(
                                                              "attribute_DOCX_value",
                                                              _vm.rep
                                                            ),
                                                          rules: _vm.getRules(
                                                            "attribute_DOCX_value"
                                                          ),
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .attribute_DOCX_value,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "attribute_DOCX_value",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].attribute_DOCX_value",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value:
                                          _vm.origin?.routeName !=
                                          "module.PEASY.PRIV",
                                        expression:
                                          "origin?.routeName != 'module.PEASY.PRIV'",
                                      },
                                    ],
                                    staticClass: "mt-2",
                                  },
                                  [
                                    _vm.form[_vm.rep].status_registry === 0
                                      ? _c(
                                          "b-card",
                                          {
                                            attrs: {
                                              header: "Altro",
                                              "header-tag": "header",
                                            },
                                          },
                                          [
                                            _c(
                                              "b-card-text",
                                              [
                                                _c("b-row", [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-radio", {
                                                        attrs: {
                                                          vid: "attribute_MARS_value",
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ].attribute_MARS_value
                                                            .label,
                                                          label:
                                                            _vm.getDictionary(
                                                              "attribute_MARS_value",
                                                              _vm.rep
                                                            ),
                                                          options:
                                                            _vm.beForm[_vm.rep]
                                                              .attribute_MARS_value
                                                              .options,
                                                          rules: _vm.getRules(
                                                            "attribute_MARS_value"
                                                          ),
                                                          stacked: "",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .attribute_MARS_value,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "attribute_MARS_value",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].attribute_MARS_value",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]),
                                                _vm._v(" "),
                                                _c("b-row", [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-input", {
                                                        attrs: {
                                                          vid: "attribute_PROF_value",
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ].attribute_PROF_value
                                                            .label,
                                                          label:
                                                            _vm.getDictionary(
                                                              "attribute_PROF_value",
                                                              _vm.rep
                                                            ),
                                                          placeholder:
                                                            "Inserisci una professione",
                                                          rules: _vm.getRules(
                                                            "attribute_PROF_value"
                                                          ),
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .attribute_PROF_value,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "attribute_PROF_value",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].attribute_PROF_value",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-select", {
                                                        attrs: {
                                                          vid: "attribute_CHAN_value",
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ].attribute_CHAN_value
                                                            .label,
                                                          label:
                                                            _vm.getDictionary(
                                                              "attribute_CHAN_value",
                                                              _vm.rep
                                                            ),
                                                          options:
                                                            _vm.beForm[_vm.rep]
                                                              .attribute_CHAN_value
                                                              .options,
                                                          rules: _vm.getRules(
                                                            "attribute_CHAN_value"
                                                          ),
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .attribute_CHAN_value,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "attribute_CHAN_value",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].attribute_CHAN_value",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value:
                                          _vm.customInputs[_vm.rep].length &&
                                          _vm.origin?.routeName !=
                                            "module.PEASY.PRIV",
                                        expression:
                                          "\n              customInputs[rep].length &&\n              origin?.routeName != 'module.PEASY.PRIV'\n            ",
                                      },
                                    ],
                                    staticClass: "mt-2",
                                  },
                                  [
                                    _c(
                                      "b-card",
                                      {
                                        attrs: {
                                          header: "Attributi personalizzati",
                                          "header-tag": "header",
                                        },
                                      },
                                      [
                                        _c(
                                          "b-card-text",
                                          [
                                            _c(
                                              "b-row",
                                              _vm._l(
                                                _vm.customInputs[_vm.rep],
                                                function (element, index) {
                                                  return _c("custom-inputs", {
                                                    key: index,
                                                    attrs: {
                                                      input: element,
                                                      beForm:
                                                        _vm.beForm[_vm.rep],
                                                      beRules:
                                                        _vm.beRules[_vm.rep],
                                                    },
                                                    model: {
                                                      value: _vm.form[_vm.rep],
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.form,
                                                          _vm.rep,
                                                          $$v
                                                        )
                                                      },
                                                      expression: "form[rep]",
                                                    },
                                                  })
                                                }
                                              ),
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "py-2 mb-4" },
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        attrs: {
                                          type: "button",
                                          disabled: invalid,
                                          variant: "lisaweb",
                                          size: "sm",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return handleSubmit(_vm.onSubmit)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n              Salva\n            "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "b-button",
                                      {
                                        attrs: {
                                          variant: "lisaweb",
                                          size: "sm",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.resetRegistryForm()
                                          },
                                        },
                                      },
                                      [_vm._v("Reset")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "b-button",
                                      {
                                        staticClass: "float-right",
                                        attrs: {
                                          variant: "lisaweb",
                                          size: "sm",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.$router.back()
                                          },
                                        },
                                      },
                                      [
                                        _c("b-icon-chevron-double-left", {
                                          attrs: { "font-scale": "0.9" },
                                        }),
                                        _vm._v("Torna indietro"),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        2763645911
                      ),
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }